import React, { Fragment } from "react"
import Navbar from "../../../Navbar"
import { Card, Col, Container, Row } from "react-bootstrap"
import useFetchStudent from "../hooks/useFetchStudent"
import useFetchClassroom from "../hooks/useFetchClassroom"
import { NoSession } from "../hero_section/NoSession"
import LoadingSpinner from "../../../../common/loading"

const LessonSpaceIframe = ({ loading, classroom, cardClass }) => {
  if (loading)
    return (
      <Col as="main" xs={24}>
        <Card className={cardClass} style={{ minHeight: "300px" }}>
          <div style={{ height: "30vh", width: "100%" }}>
            <LoadingSpinner />
          </div>
        </Card>
      </Col>
    )

  if (!classroom && !classroom?.classroom_link) {
    return (
      <Col as="main" xs={24}>
        <Card className={cardClass} style={{ minHeight: "300px" }}>
          <NoSession message={"There is no lessonspace session found"} />
        </Card>
      </Col>
    )
  }

  return (
    <iframe
      src={classroom.classroom_link}
      width="100%"
      style={{ height: "80vh" }}
      title="Lesson space session"
      allow="camera; microphone; display-capture; autoplay"
      allowfullscreen="true"
    />
  )
}

const StudentDashboardClassroomPage = ({ student_id, sign_out_button }) => {
  const scheduleId = window.location.pathname.split("/").pop()
  const { studentData, cardClass } = useFetchStudent({ student_id })
  const { classroom, loading } = useFetchClassroom({ studentId: student_id, scheduleId })

  if (!studentData) return null

  return (
    <Fragment>
      <header className="header">
        <Navbar user={studentData} sign_out_button={sign_out_button} />
      </header>
      <Container fluid className="p-0 d-flex flex-column flex-grow-1">
        <Row>
          <Col className="p-0" style={{ flexGrow: 2 }}>
            <Container className="student-dashboard-content">
              <Row>
                <LessonSpaceIframe loading={loading} classroom={classroom} cardClass={cardClass} />
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default StudentDashboardClassroomPage
