import DescriptionIcon from "@mui/icons-material/Description"
import { Divider, Link, Stack, Typography } from "@mui/material"
import PropTypes from "prop-types"
import React, { useMemo } from "react"

const LinkItem = ({ link }) => (
  <Link
    key={link.name}
    href={link.url}
    variant="body2"
    target="_blank"
    rel="noreferrer"
    sx={{
      textWrap: "balance",
    }}
  >
    {link.show_icon && (
      <DescriptionIcon
        sx={{
          color: "#3c00f5",
        }}
      />
    )}
    {link.name}
  </Link>
)

LinkItem.propTypes = {
  link: PropTypes.shape({
    name: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    show_icon: PropTypes.bool,
  }).isRequired,
}

const LevelsList = ({ levels = [] }) => {
  const renderedLevels = useMemo(() => {
    return levels.map((level, i) => (
      <Stack key={i} spacing={1} sx={{ mx: "auto", p: 1 }}>
        <Typography variant="h6" className="font-weight-bold">
          {level.name}
        </Typography>
        <Typography component="p" variant="body2" color="text.primary">
          {level.description}
        </Typography>
        <Stack>
          <Typography component="span" variant="body2" color="text.primary">
            Resources
          </Typography>
          <Stack direction="row" spacing={1}>
            {level.links.map(link => (
              <LinkItem link={link} key={link.name} />
            ))}
          </Stack>
        </Stack>
      </Stack>
    ))
  }, [levels])

  return (
    <Stack spacing={2} divider={<Divider />}>
      {renderedLevels}
    </Stack>
  )
}

LevelsList.propTypes = {
  levels: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      links: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string.isRequired,
          url: PropTypes.string.isRequired,
          show_icon: PropTypes.bool,
        })
      ).isRequired,
    })
  ).isRequired,
}

export default React.memo(LevelsList)
