import { MeetingFormats } from "@/constants"
import TimerSharpIcon from "@mui/icons-material/TimerSharp"
import { Button, Card, Typography } from "@mui/material"
import { styled } from "@mui/system"
import moment from "moment"
import React from "react"
// import { useGetUpcomingSession } from "../hooks/useGetUpcomingSession"

const mockedData = {
  next_session: {
    formatted_scheduled_at_text: "Tomorrow At 1:00pm",
    scheduled_at: "2024-09-12T13:00:00.000Z",
    location: "online",
    address: "",
  },
}

const JoinSessionButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.primary.dark,
  },
  maxWidth: "150px",
}))

const NextSessionContainer = styled(Card)`
  border-top: 5px solid #71c8db;
  padding: 30px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
  flex: 1;
`

const SessionDetails = styled("div")`
  flex: 1;
`

const SessionAction = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`

const Text = styled(Typography)`
  letter-spacing: 0;
`

// eslint-disable-next-line no-unused-vars
export const NextSessionForProgram = ({ tutorId }) => {
  //   const { loading, data: upcomingSessionData } = useGetUpcomingSession({ tutorId })
  const {
    next_session: {
      formatted_scheduled_at_text: sessionStartsIn,
      scheduled_at: sessionScheduledAt,
      location,
      address,
      schedule_id: scheduleId,
    },
  } = mockedData

  const sessionDate = moment(sessionScheduledAt)
  const currentTime = moment()
  const canStartNextSession = currentTime.isSameOrAfter(sessionDate.subtract(5, "minutes"))

  const isOfflineSession = location === MeetingFormats.Offline

  const onJoinClassClick = e => {
    e.preventDefault()

    if (location === MeetingFormats.Online) {
      window.open(address, "_blank")
    }

    if (location === MeetingFormats.LessonSpace && scheduleId) {
      window.open(`/tutor/dashboard/classroom/${scheduleId}`, "_blank")
    }
  }

  return (
    <NextSessionContainer>
      <div className="d-flex justify-content-between">
        <SessionDetails>
          <Typography
            variant="h6"
            component="div"
            color="textPrimary"
            gutterBottom
            sx={{ display: "flex", alignItems: "center", gap: 1, mb: 1 }}
          >
            <TimerSharpIcon style={{ color: "rgb(240, 35, 14)" }} />
            Up next
          </Typography>
          <Text variant="body2" component="div" gutterBottom>
            <strong>{sessionStartsIn}: </strong> Foundation ELA with
            {[
              { first_name: "Student 1", last_name: "Last Name" },
              { first_name: "Student 2", last_name: "Last Name" },
            ].map(({ first_name, last_name }) => (
              <a key={`${first_name}${last_name}`} href="#" style={{ marginLeft: "0.5rem", marginRight: "0.25rem" }}>
                {first_name} {last_name}
              </a>
            ))}
          </Text>
          <Text variant="body2" component="div" gutterBottom>
            <strong>Agenda: </strong> Complete diagnostic assessment (reading) with [names of students who specifically
            need action taken]
          </Text>
          <Text variant="body2" component="div">
            <strong>Action Item: </strong> <a href="#">Preview diagnostic assessment (reading)</a>
          </Text>
        </SessionDetails>
        {!isOfflineSession && (
          <SessionAction>
            <JoinSessionButton
              size="small"
              variant="contained"
              elevation="0"
              onClick={onJoinClassClick}
              disabled={!canStartNextSession}
            >
              Join Session
            </JoinSessionButton>
            {!canStartNextSession && (
              <Text
                variant="caption"
                color="textSecondary"
                sx={{ maxWidth: "150px", marginTop: "0.5rem", textWrap: "balance", textAlign: "center" }}
              >
                Link will become active 5 minutes prior to start time
              </Text>
            )}
          </SessionAction>
        )}
      </div>
    </NextSessionContainer>
  )
}
