import BRTThemeProvider from "@/providers/theme"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import Accordion from "@mui/material/Accordion"
import AccordionDetails from "@mui/material/AccordionDetails"
import AccordionSummary from "@mui/material/AccordionSummary"
import Typography from "@mui/material/Typography"
import PropTypes from "prop-types"
import React, { useMemo } from "react"
import styled from "styled-components"
import LevelsList from "./levels_list"

const StyledAccordion = styled(Accordion)`
  & .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
    min-height: 48px;
  }

  .MuiAccordionSummary-root {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      background-color: #e8e8e8;
    }
  }

  .MuiAccordionDetails-root {
    padding: 12px;
    background-color: #ffffff;
  }
`

const createData = (name, levels) => ({
  name,
  levels,
})

const CollapsibleTable = ({ data }) => {
  const rows = useMemo(() => data.map(item => createData(item.name, item.levels)), [data])

  return (
    <BRTThemeProvider>
      {rows.map(row => (
        <StyledAccordion key={row.name}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography sx={{ fontWeight: "600" }}>{row.name}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <LevelsList levels={row.levels} />
          </AccordionDetails>
        </StyledAccordion>
      ))}
    </BRTThemeProvider>
  )
}

CollapsibleTable.defaultProps = {
  data: [
    createData("Diagnostic Assessment", [
      {
        description:
          "Description of Diagnostic Assessment, what a tutor, parent or school admin might find interesting",
        links: [
          {
            name: "Diagnostic Assessment",
            url: "https://develop.braintrusttutors.com",
            show_icon: true,
          },
        ],
      },
    ]),
    createData("Level 1: Consonants & Short Vowels", [
      {
        name: "Lesson 1.1: a, t, m, s",
        description:
          "Description of Diagnostic Assessment, what a tutor, parent or school admin might find interesting",
        links: [
          {
            name: "Lesson Plan 1.1",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Teaching Slides",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Reading Passages: Sam and Pam",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Interactive Game",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Activity",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
        ],
      },
      {
        name: "Lesson 1.2: c, s, d, r",
        description:
          "Description of Diagnostic Assessment, what a tutor, parent or school admin might find interesting",
        links: [
          {
            name: "Lesson Plan 1.1",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Teaching Slides",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Reading Passages: Sam and Pam",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Interactive Game",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
          {
            name: "Activity",
            url: "https://develop.braintrusttutors.com",
            show_icon: false,
          },
        ],
      },
    ]),
  ],
}

CollapsibleTable.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      levels: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          description: PropTypes.string.isRequired,
          links: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string.isRequired,
              url: PropTypes.string.isRequired,
              show_icon: PropTypes.bool.isRequired,
            })
          ),
        })
      ).isRequired,
    })
  ).isRequired,
}

export default CollapsibleTable
