import { ReactComponent as EyeIcon } from "@/assets/images/eye.svg"
import { ReactComponent as TrainingCenter } from "@/assets/images/training_center.svg"
import { formatDate } from "@/helpers/dates"
import { Box, Link as MuiLink, Stack, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"
import Grid from "@mui/material/Unstable_Grid2"
import React, { useEffect, useState } from "react"
import AdminsMessage from "./AdminsMessage"
import { useGetAdminMessageRequest, useTutorScheduleRequest } from "./hooks"
import { NextSession } from "./NextSession"
import { NextSessionForProgram } from "./NextSessionForProgram"
import { OpportunitiesList } from "./OpportunitiesList"
import { TutorDashboardProvider } from "./providers"
import { UserDashboard } from "./UserDashboard"
import WeekSchedule from "./WeekSchedule"

const links = [
  {
    icon: EyeIcon,
    href: "https://docs.google.com/document/d/11XBrI-KHUp_DzgO13y8TSU3Lh1sqKZ4vZjamzI-IZ9o/export?format=pdf",
    title: "Guide to Braintrust",
  },
  {
    icon: TrainingCenter,
    href: "https://k12.instructure.com/enroll/A7TDKC",
    title: "Training Center",
  },
]

const Title = styled(Typography)`
  color: #333;
  letter-spacing: -0.04em;
`

const LinkIcon = styled("div")`
  width: 24px;
  height: 24px;
  fill: #666;
`

const Link = styled(MuiLink)`
  color: #333;
  text-decoration: none;

  &:hover {
    color: #000;
  }
`

const LinkRow = ({ icon: Icon, href, title }) => {
  return (
    <Stack direction="row" spacing={1} alignItems="center">
      <LinkIcon className="dashboard_link-icon d-flex align-items-center justify-content-center">
        <Icon />
      </LinkIcon>
      <Link href={href} target="_blank" className="font-weight-medium" rel="noreferrer">
        {title}
      </Link>
    </Stack>
  )
}

const LinksPanel = () => {
  return (
    <Box className="panel panel_body w-100 p-4">
      <Stack spacing={2}>
        {links.map((linkData, idx) => (
          <LinkRow key={idx} {...linkData} />
        ))}
      </Stack>
    </Box>
  )
}

const TutorDashboardContent = ({ currentUser, calendarPath, links }) => {
  const userName = currentUser?.first_name
  const tutorId = currentUser?.id
  const { feature_flags } = currentUser
  const [selectedDate, setSelectedDate] = useState(() => formatDate(new Date()))
  const updateSelectedDate = date => {
    setSelectedDate(() => formatDate(date))
  }

  const { loading: adminMessageLoading, data: adminMessage } = useGetAdminMessageRequest()

  const {
    loading: tutorScheduleLoading,
    data: tutorScheduleEvents,
    request: tutorScheduleRequest,
  } = useTutorScheduleRequest()

  useEffect(() => {
    tutorScheduleRequest(selectedDate)
  }, [tutorScheduleRequest, selectedDate])

  const globalLoading = adminMessageLoading && tutorScheduleLoading

  if (globalLoading) {
    return (
      <div className="spinner-wrapper">
        <div className="spinner-border text-primary" />
      </div>
    )
  }

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={4} sx={{ pt: 2 }}>
        <Title variant="h3" sx={{ ml: 2 }}>
          Welcome back, {userName}!
        </Title>
        <Grid xs={12} className="container panel-wrapper" sx={{ px: 0 }}>
          {feature_flags["curriculum_enabled"] ? (
            <NextSessionForProgram tutorId={tutorId} />
          ) : (
            <NextSession tutorId={tutorId} />
          )}
        </Grid>

        <Grid xs={12} className="container panel-wrapper" sx={{ px: 0 }}>
          <WeekSchedule
            title={"Week Schedule"}
            loading={tutorScheduleLoading}
            week={selectedDate}
            setWeek={updateSelectedDate}
            events={tutorScheduleEvents}
            calendarPath={calendarPath}
          />
        </Grid>

        <Grid spacing={2} xs={12} container>
          <Grid xs={12} className="dashboard_tutor_message -mobile">
            <Grid xs={12}>
              <AdminsMessage message={adminMessage} />
            </Grid>
            <Grid xs={12}>
              <LinksPanel tutorGuide={links.guide} askQuestion={links.ask} />
            </Grid>
          </Grid>
          <Grid xs={8} className="container panel-wrapper">
            <OpportunitiesList />
          </Grid>
          <Grid xs={4} spacing={2} className="container panel-wrapper dashboard_tutor_message -desktop">
            <Box className="dashboard_tutor_message_wrapper">
              <Stack spacing={2}>
                <UserDashboard />
                <AdminsMessage message={adminMessage} />
                <LinksPanel tutorGuide={links.guide} askQuestion={links.ask} />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

const TutorDashboard = props => {
  return (
    <TutorDashboardProvider>
      <TutorDashboardContent {...props} />
    </TutorDashboardProvider>
  )
}

export default TutorDashboard
