import { MeetingFormats } from "@/constants"
import { Button } from "@mui/material"
import moment from "moment"
import React, { Fragment } from "react"
import { useGetUpcomingSession } from "../hooks/useGetUpcomingSession"

export const NextSession = ({ tutorId }) => {
  const { loading, data: upcomingSessionData } = useGetUpcomingSession({ tutorId })

  if (!upcomingSessionData || loading) {
    return null
  }

  const {
    next_session: {
      schedule_id: scheduleId,
      formatted_scheduled_at_text: sessionStartsIn,
      scheduled_at: sessionScheduledAt,
      location,
      address,
    },
  } = upcomingSessionData

  const canStartNextSession = moment(sessionScheduledAt).isBefore(moment().add(5, "minutes"))
  const isOfflineSession = location === MeetingFormats.Offline

  const onJoinClassClick = e => {
    e.preventDefault()

    if (location === MeetingFormats.Online) {
      window.open(address, "_blank")
    }

    if (location === MeetingFormats.LessonSpace && scheduleId) {
      window.open(`/tutor/dashboard/classroom/${scheduleId}`, "_blank")
    }
  }

  return (
    <Fragment>
      <div className="panel panel_body min-h-90-px d-block mb-0">
        <div className="d-flex justify-content-between align-items-center">
          <div className="flex-1">
            <h4>
              Your next session begins <span style={{ color: "#3c00f5" }}>{sessionStartsIn}</span>
            </h4>
            {isOfflineSession && (
              <ul>
                <li>
                  <b>Address: {address}</b>
                </li>
              </ul>
            )}
          </div>
          {!isOfflineSession && (
            <div className="flex-1">
              <Button
                size="sm"
                variant="contained"
                onClick={onJoinClassClick}
                disabled={isOfflineSession || !canStartNextSession}
              >
                Join Class
              </Button>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  )
}
