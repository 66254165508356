import moment from "moment"
import React from "react"
import { Navigate } from "react-router-dom"
import Curriculum from "../../opening_module/pages/curriculum"
import Navigation from "../navigation"
import { ReportNavigation } from "../navigation/report_navigation"
import Report from "../report"
import Schedule from "../schedule"
import NewSchedulePage from "../schedule/new"
import { Students } from "../students"
import { Tutors } from "../tutors"

const root = "/"

const routes = [
  {
    routeName: "",
    routeTitleName: "Schedule",
    routesPatterns: "/:scheduleId",
  },
  {
    routeName: "students",
    routeTitleName: "Students",
    routesPatterns: "/:scheduleId/students",
  },
  {
    routeName: "tutors",
    routeTitleName: "Tutors",
    routesPatterns: "/:scheduleId/tutors",
  },
  {
    routeName: "reports",
    routeTitleName: "Reports",
    routesPatterns: "/:scheduleId/reports/:report_date",
  },
  {
    routeName: "curriculum",
    routeTitleName: "Curriculum",
    routesPatterns: "/:scheduleId/curriculum",
    featureFlagName: "curriculum_enabled",
  },
]

export const RouteNames = Object.freeze({
  Schedules: "",
  Students: "students",
  Tutors: "tutors",
  Reports: "reports",
  Curriculum: "curriculum",
})

export const RoutesTitleNames = Object.freeze({
  [RouteNames.Schedules]: "Schedule",
  [RouteNames.Reports]: "Report",
  [RouteNames.Students]: "Students",
  [RouteNames.Tutors]: "Tutors",
  [RouteNames.Curriculum]: "Curriculum",
})

export const RoutesPatterns = Object.freeze({
  [RouteNames.Schedules]: "/:scheduleId",
  [RouteNames.Reports]: `/:scheduleId/${RouteNames.Reports}/:report_date`,
  [RouteNames.Tutors]: `/:scheduleId/${RouteNames.Tutors}`,
  [RouteNames.Students]: `/:scheduleId/${RouteNames.Students}`,
  [RouteNames.Curriculum]: `/:scheduleId/${RouteNames.Curriculum}`,
})

const currentDate = moment().startOf(new Date()).format("YYYY-MM-DD")

const showBasedOnFeatureFlag = (featureFlags, navigationItems) => {
  if (!featureFlags) return navigationItems

  return navigationItems.filter(nav => {
    if (!nav.featureFlagName) return true
    return !!featureFlags[nav.featureFlagName]
  })
}

const scheduleNavigationItems = [
  {
    path: RouteNames.Schedules,
    element: <Schedule />,
  },
  {
    path: RouteNames.Students,
    element: <Students />,
  },
  {
    path: RouteNames.Reports,
    element: <ReportNavigation />,
    children: [
      {
        path: "",
        element: <Navigate to={currentDate} />,
      },
      {
        path: ":report_date",
        element: <Report />,
      },
      {
        path: "*",
        element: <Navigate to={currentDate} />,
      },
    ],
  },
  {
    path: RouteNames.Tutors,
    element: <Tutors />,
  },
  {
    path: RouteNames.Curriculum,
    element: <Curriculum />,
    featureFlagName: "curriculum_enabled",
  },
]

export const getNavigationConfig = initial => [
  {
    path: root,
    element: <Navigation initial={initial} />,
    children: [
      {
        path: ":scheduleId",
        children: showBasedOnFeatureFlag(initial?.currentUser?.feature_flags, scheduleNavigationItems),
      },
    ],
  },
  {
    path: "new",
    element: <NewSchedulePage />,
  },
]

export const tabsNavigationConfig = currentUser =>
  showBasedOnFeatureFlag(currentUser?.feature_flags, routes).map(route => ({
    route,
    pattern: route.routesPatterns,
    title: route.routeTitleName,
  }))
