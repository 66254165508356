import React from "react"
import CollapsibleTable from "./collapsible_table"

const CurriculumPage = () => {
  return (
    <div className="card px-4 py-4 session-information">
      <h1 className="font-weight-semibold mb-0 flex-grow-1">Foundational Reading</h1>
      <h6 className="font-size-medium font-weight-semibold extra-dark-gray">Foundational Reading description</h6>

      <hr className="divider fullwidth soft-color" />
      <div className="d-flex ">
        <div className="w-100">
          <CollapsibleTable />
        </div>
      </div>
    </div>
  )
}

export default CurriculumPage
